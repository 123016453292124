export const features = () => {
  $(".faq-item-title-container").on("click", function () {
    $(this).toggleClass("active");
    $(this)
      .siblings(".faq-item-question-container")
      .each(function () {
        $(this).slideToggle(300);
      });
    $(this)
      .closest(".faq-item")
      .siblings(".faq-item")
      .each(function () {
        $(this).find(".faq-item-title-container").removeClass("active");
        $(this).find(".faq-item-question-container").slideUp(300);
      });
  });

  const forms = document.querySelectorAll(".wpcf7");
  const header = document.querySelector("#header");
  const body = document.querySelector("body");
  const modal = document.querySelector(".backdrop-main");
  const modalButtonClose = document.querySelectorAll(".modal-button-close");
  const modalButtons = document.querySelectorAll(".modal-button");
  modalButtons.forEach((modalButton) => {
    modalButton.addEventListener("click", (e) => {
      e.preventDefault();
      forms.forEach((form) => {
        const modalWrapper = document.querySelector(".modal-main");
        modal.classList.remove("is-hidden");
        body.classList.add("overflow-hidden");
        modalButtonClose.forEach((e) => {
          e.addEventListener("click", () => {
            modal.classList.add("is-hidden");
            body.classList.remove("overflow-hidden");
          });
        });
        let formWrapper = form.closest("#kontakt");
        form.addEventListener(
          "wpcf7mailsent",
          function (event) {
            let wrapper = form.closest(".contact-form-wrapper");
            let thanks = form.closest(".contact-form").querySelector(".contact-form-thanks");
            let headerHeight = header.offsetHeight;
            let top = formWrapper.offsetTop - headerHeight - 10;
            $("body,html").animate({ scrollTop: top }, 400);
            wrapper.classList.add("hide");
            thanks.classList.add("show");
            modalWrapper.classList.add("active");
            setTimeout(() => {
              wrapper.classList.remove("hide");
              thanks.classList.remove("show");
              modalWrapper.classList.remove("active");
            }, 8000);
          },
          false
        );
      });
    });
  });

  // ANCHORS
  $(".mob-menu a").on("click", function (e) {
    if ($(this).attr("href").indexOf("#") >= 0) {
      let firstIndex = $(this).attr("href").indexOf("#");
      let lastIndex = $(this).attr("href").length;
      let id = $(this).attr("href").substring(firstIndex, lastIndex);
      if ($(id).length > 0) {
        //e.preventDefault();
        $("#page").each(function () {
          $(this).removeClass("active");
        });
        $(".header-mobile-burger").each(function () {
          $(this).removeClass("active");
        });
        $(".header-menu-mob").each(function () {
          $(this).addClass("scale-y-0");
        });
        // $(".header-bg").each(function () {
        //   $(this).removeClass("hidden");
        // });
        $("body").each(function () {
          $(this).removeClass("overflow-hidden");
        });
        let top = $(id).offset().top;
        $("body,html").animate({ scrollTop: top }, 1500);
      }
    }
  });

  $(".footer-menu a").on("click", function (e) {
    if ($(this).attr("href").indexOf("#") >= 0) {
      let firstIndex = $(this).attr("href").indexOf("#");
      let lastIndex = $(this).attr("href").length;
      let id = $(this).attr("href").substring(firstIndex, lastIndex);
      if ($(id).length > 0) {
        // e.preventDefault();
        let top = $(id).offset().top;
        $("body,html").animate({ scrollTop: top }, 1500);
      }
    }
  });
  function windowHrefAnchor() {
    const href = window.location.href;
    if (href.indexOf("#") >= 0) {
      let firstIndex = href.indexOf("#");
      let lastIndex = href.length;
      let id = href.substring(firstIndex, lastIndex);
      if ($(id).length > 0) {
        let top = $(id).offset().top;
        $("body,html").animate({ scrollTop: top }, 1500);
      }
    }
  }
  windowHrefAnchor();

  // burger
  const btns = document.querySelectorAll(".header-mobile-burger");
  const menus = document.querySelectorAll(".header-menu-mob");
  const page = document.querySelector("#page");

  btns.forEach((btn) => {
    btn.addEventListener("mouseenter", () => {
      btn.classList.add("hover");
    });

    btn.addEventListener("mouseleave", () => {
      btn.classList.remove("hover");
    });

    btn.addEventListener("click", () => {
      btn.classList.toggle("active");
      btn.classList.remove("hover");
      toggleMenu();
    });
  });

  document.addEventListener("click", (event) => {
    const isMenuClick = Array.from(menus).some((menu) => menu.contains(event.target));
    const isBtnClick = Array.from(btns).some((btn) => btn.contains(event.target));

    if (!isMenuClick && !isBtnClick) {
      closeMenu();
    }
  });

  const toggleMenu = () => {
    menus.forEach((menu) => {
      menu.classList.toggle("scale-y-0");
    });
    page.classList.toggle("active");
    document.querySelector("body").classList.toggle("overflow-hidden");
  };

  const closeMenu = () => {
    menus.forEach((menu) => {
      // Перевіряємо, чи меню вже відкрите, і тільки тоді закриваємо його
      if (!menu.classList.contains("scale-y-0")) {
        menu.classList.add("scale-y-0");
        page.classList.remove("active");
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    });
  };
};
